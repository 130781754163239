//Fuuka Yamagishi, 2006
import React from "react";
import { Page } from "../../Components";
import "./homepage.css";
import pizzaGuy from "../../imgs/1526180481772.jpg";

const Home = () => {
  function getTitleText() {
    let titleText;
    switch (Math.floor(Math.random() * 6)) {
      case 1:
        titleText = `     _    U  _____  u  __  __    U  ___ u  _   _    ____     
    |"|    \\| ___"|/ U|' \\/ '|u   \\/"_ \\/ | \\ |"|  / __"| u  
  U | | u   |  _|    \\| |\\/| |/   | | | |<|  \\| |><\\___ \\/   
   \\| |/__  | |___    | |  | |.-,_| |_| |U| |\\  |u u___) |   
    |_____| |_____|   |_|  |_| \\_)-\\___/  |_| \\_|  |____/>>  
    //  \\\\  <<   >>  <<,-,,-.       \\\\    ||   \\\\   )(  (__) 
   (_")("_)(__) (__)  (./  \\.)     (__)   (_")  (_/(__)      
  `;
        break;
      case 2:
        titleText = `
        .---.       .-''-.  ,---.    ,---.    ,-----.    ,---.   .--.   .-'''-.  
        | ,_|     .'_ _   \\ |    \\  /    |  .'  .-,  '.  |    \\  |  |  / _     \\ 
      ,-./  )    / \( \` )   '|  ,  \\/  ,  | / ,-.|  \\ _ \\ |  ,  \\ |  | (\`' )/\`--' 
      \\  '_ '\`) . (_ o _)  ||  |\\_   /|  |;  \\  '_ /  | :|  |\\_ \\|  |(_ o _).    
       > (_)  ) |  (_,_)___||  _( )_/ |  ||  _\`,/ \\ _/  ||  _( )_\\  | (_,_). '.  
      (  .  .-' '  \\   .---.| (_ o _) |  |: (  '\\_/ \\   ;| (_ o _)  |.---.  \\  : 
       \`-'\`-'|___\\  \`-'    /|  (_,_)  |  | \\ \`"/  \\  ) / |  (_,_)\\  |\\    \`-'  | 
        |        \\\\       / |  |      |  |  '. \\_/\`\`".'  |  |    |  | \\       /  
        \`--------\` \`'-..-'  '--'      '--'    '-----'    '--'    '--'  \`-...-'   
                                                                                 
      `;
        break;
      case 3:
        titleText = ` 
 __                                                       
|  \\                                                      
| $$      ______  ______ ____   ______  _______   _______ 
| $$     /      \\|      \\    \\ /      \\|       \\ /       \\
| $$    |  $$$$$$| $$$$$$\\$$$$|  $$$$$$| $$$$$$$|  $$$$$$$
| $$    | $$    $| $$ | $$ | $| $$  | $| $$  | $ \\$$    \\ 
| $$____| $$$$$$$| $$ | $$ | $| $$__/ $| $$  | $ _\\$$$$$$\\
| $$     \\$$     | $$ | $$ | $ \\$$    $| $$  | $|       $$
\\$$$$$$$$\\$$$$$$$\\$$  \\$$  \\$  \\$$$$$$ \\$$   \\$$\\$$$$$$$`;
        break;
      case 4:
        titleText = `
        ___      _______  __   __  _______  __    _  _______ 
       |   |    |       ||  |_|  ||       ||  |  | ||       |
       |   |    |    ___||       ||   _   ||   |_| ||  _____|
       |   |    |   |___ |       ||  | |  ||       || |_____ 
       |   |___ |    ___||       ||  |_|  ||  _    ||_____  |
       |       ||   |___ | ||_|| ||       || | |   | _____| |
       |_______||_______||_|   |_||_______||_|  |__||_______|
       `;
        break;
      case 5:
        titleText = `
          _                                             
         | |      ___    _ __     ___    _ _      ___   
         | |__   / -_)  | '  \\   / _ \\  | '  \\   (_-<   
         |____|  \\___|  |_|_|_|  \\___/  |_||_|   /__/_  
       _|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""| 
       "\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-' 
       `;
        break;
      default: // Or 0, rather
        titleText = `
        ,-.    ,---.           .---.  .-. .-.   .---. 
        | |    | .-'  |\\    /|/ .-. ) |  \\| |  ( .-._)
        | |    | \`-.  |(\\  / || | |(_)|   | | (_) \\   
        | |    | .-'  (_)\\/  || | | | | |\\  | _  \\ \\  
        | \`--. |  \`--.| \\  / |\\ \`-' / | | |)|( \`-'  ) 
        |( __.'/( __.'| |\\/| | )---'  /(  (_) \`----'  
        (_)   (__)    '-'  '-'(_)    (__)            `;
        break;
    }
    return titleText;
  }

  return (
    <div className="homepage">
      <Page>
        <div className="titleRow">
          <pre className="lemon">
            <span className="green">{`                    ⢀⣀⣀⣀⣀⣀⣀⣀⣀⣀⠀⠀⠀⠀⠀`}</span>
            <br />
            <span className="green">{`        ⠀⠀⠀⠀⢀⣀⣠⣤⣴⣶⡶⢿⣿⣿⣿⠿⠿⠿⠿⠟⠛⢋⣁⣤⡴⠂`}</span>
            {`⣠⡆`}
            <br />
            <span className="green">{`        ⠀⠀⠀⠈⠙⠻⢿⣿⣿⣿⣶⣤⣤⣤⣤⣤⣴⣶⣶⣿⣿⣿⡿⠋`}</span>
            {`⣠⣾⣿⠁`}
            <br />
            {`           ⢀⣴⣤⣄`}
            <span className="green">{`⠉⠛⠻⠿⠿⣿⣿⣿⣿⡿⠿⠟⠋⠁`}</span>
            {`⣠⣾⣿⣿⣿`}
            <br />
            {`           ⣠⣾⣿⣿⣿⣿⣿⣶⣶⣤⣤⣤⣤⣤⣤⣶⣾⣿⣿⣿⣿⣿⣿⣿⡇`}
            <br />
            {`          ⣰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇`}
            <br />
            {`         ⢰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠁`}
            <br />
            {`       ⢀⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠇⢸⡟⢸⡟`}
            <br />
            {`       ⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⢿⣷⡿⢿⡿⠁`}
            <br />
            {`      ⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡟⢁⣴⠟⢀⣾⠃`}
            <br />
            {`     ⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠛⣉⣿⠿⣿⣶⡟⠁`}
            <br />
            {`   ⢿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠛⣿⣏⣸⡿⢿⣯⣠⣴⠿⠋`}
            <br />
            {` ⢸⣿⣿⣿⣿⣿⣿⣿⣿⠿⠶⣾⣿⣉⣡⣤⣿⠿⠛⠁`}
            <br />
            {`⢸⣿⣿⣿⣿⡿⠿⠿⠿⠶⠾⠛⠛⠛⠉⠁    `}
            <br />
            {`⠈⠉⠉⠉                    `}
          </pre>
          <pre className="title">{getTitleText()}</pre>
        </div>
        {/* Have an empty page with "random events" on it for a home-screen */}
        <img
          className="pizzaGuyPicture"
          alt="Guy uses his love for pizza to power telekinesis upon pizza"
          src={pizzaGuy}
        />
      </Page>
      <span className="copyrightArea">
        I own everything in the world I am the illuminati
      </span>
    </div>
  );
};

export default Home;
