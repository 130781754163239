import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/homepage";
import KarutaPricing from "./Pages/karutaPricing";
//HashRouter WAS BrowserRouter, but I switched it to fix mmb clicking links not working.

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/karutaPricing" element={<KarutaPricing />} />
        </Routes>
      </Router>
    </div>
  );
}
{
  /* <script> 
        window.onload = () => {
          //I think this on-load random theme selecting code might be better off in app.js
          function determineScheme(options) {
            var link = document.getElementById("cssLink"); //Fetch the link by its ID
            switch (options) {
              case 1:
                link.setAttribute("href", "css/colorScheme1.css");
                break;
              case 2:
                link.setAttribute("href", "css/colorScheme2.css");
                break;
              case 3:
                link.setAttribute("href", "css/colorScheme3.css");
                break;
              case 4:
                link.setAttribute("href", "css/colorScheme4.css");
                break;
              case 5:
                link.setAttribute("href", "css/colorScheme5.css");
                break;
              case 6:
                link.setAttribute("href", "css/colorScheme6.css");
                break;
              case 7:
                link.setAttribute("href", "css/colorScheme7.css");
                break;
              case 8:
                link.setAttribute("href", "css/colorScheme8.css");
                break;
              case 9:
                link.setAttribute("href", "css/colorScheme9.css");
                break;
              case 10:
                link.setAttribute("href", "css/colorScheme10.css");
                break;
              case 11:
                link.setAttribute("href", "css/colorScheme11.css");
                break;
              case 31:
                link.setAttribute("href", "css/colorScheme31.css");
                break;
              case 312:
                link.setAttribute("href", "css/colorScheme31two.css");
                break;
              case 25:
                link.setAttribute("href", "css/colorScheme25.css");
                break;
              default:
                link.setAttribute("href", "css/colorScheme25.css");
                console.log("invalid color " + options);
                break;
            }
          }
        </script>*/
}
export default App;
